import AxiosConfig from '@/api/config'
import { fetchAndStoreData } from '.'
import db from '@/offline/indexedDBHelper'

const baseURL = '/operatives'

const getStatuses = async () => {
    const url = `${baseURL}/status-types`

    return await fetchAndStoreData(url, db.operatives_statuses)
}

const getLevels = async () => {
    const url = `${baseURL}/level-types`

    return await fetchAndStoreData(url, db.operatives_levels)
}

const checkIn = async (payload) => {
    try {
        const url = `${baseURL}/checkin`

        const { data } = await AxiosConfig.post(url, payload)

        if (!data) return []

        return data
    } catch (error) {
        console.error('Error in checkIn:', error)
        return []
    }
}

const checkOut = async (payload) => {
    try {
        const url = `${baseURL}/checkout`

        const { data } = await AxiosConfig.post(url, payload)

        if (!data) return []

        return data
    } catch (error) {
        console.error('Error in checkOut:', error)
        return []
    }
}

const switchTeamLead = async (payload) => {
    try {
        const url = `${baseURL}/checkout`

        const { data } = await AxiosConfig.post(url, payload)

        if (!data) return []

        return data
    } catch (error) {
        console.error('Error in switchTeamLead:', error)
        return []
    }
}

const addSignature = async (payload) => {
    try {
        const url = '/operative-signature'

        const { data } = await AxiosConfig.post(url, payload)

        if (!data) return []

        return data
    } catch (error) {
        console.error('Error in addSignature:', error)
        return []
    }
}

const getSignatures = async (payload) => {
    try {
        const url = '/operative-signature/'

        const { data } = await AxiosConfig.get(url, { params: payload })

        if (!data) return []

        return data
    } catch (error) {
        console.error('Error in getSignatures:', error)
        return []
    }
}

export default {
    checkIn,
    checkOut,
    getLevels,
    getStatuses,
    switchTeamLead,
    addSignature,
    getSignatures
}
