import AxiosConfig from '@/api/config'
import { fileNameParser } from '@/utils/Utilities'

const getAll = async (modelName, params) => {
    try {
        const url = '/' + modelName

        const { data } = await AxiosConfig.get(url, { params })

        if (!data) return []

        return data
    } catch (error) {
        console.error('Error in getAll:', error)
        return []
    }
}

const getModelByID = async (modelName, modelID) => {
    try {
        const url = `/${modelName}/${modelID}`

        const { data } = await AxiosConfig.get(url)

        if (!data) return {}

        return data.data
    } catch (error) {
        console.error('Error in getModelByID:', error)
        return {}
    }
}

const createModel = async (modelName, modelPayload) => {
    try {
        const url = `/${modelName}`

        const { data } = (await AxiosConfig.post(url, modelPayload)) || {}

        if (!data) return {}

        return data.data
    } catch (error) {
        console.error('Error in createModel:', error)
        return {}
    }
}

const updateModel = async (modelName, modelID, modelPayload) => {
    try {
        const url = `/${modelName}/${modelID}`

        const { data } = (await AxiosConfig.patch(url, modelPayload)) || {}

        if (!data) return {}

        return data.data
    } catch (error) {
        console.error('Error in updateModel:', error)
        return {}
    }
}

const deleteModel = async (modelName, modelID, endpoint = '') => {
    try {
        const url = `/${modelName}/${modelID}/${endpoint}`

        const { data } = (await AxiosConfig.delete(url)) || {}

        if (!data) return {}

        return data
    } catch (error) {
        console.error('Error in deleteModel:', error)
        return {}
    }
}

const restoreModel = async (modelName, modelID) => {
    try {
        const url = `/${modelName}/${modelID}/restore`

        const { data } = (await AxiosConfig.post(url)) || {}

        if (!data) return {}

        return data
    } catch (error) {
        console.error('Error in restoreModel:', error)
        return {}
    }
}

const getModelAttachment = async (params) => {
    try {
        const url = '/download-file'
        const filename =
            params.original_filename ||
            fileNameParser(params.filename, params.type)
        const requiredParams = { filename: params.filename, type: params.type }

        const { data } = await AxiosConfig.get(url, {
            params: requiredParams,
            responseType: 'blob'
        })

        const fileURL = window.URL.createObjectURL(new Blob([data]))
        const fileLink = document.createElement('a')

        fileLink.href = fileURL
        fileLink.setAttribute('download', filename)
        document.body.appendChild(fileLink)

        fileLink.click()

        if (!data) return []

        return data
    } catch (error) {
        console.error('Error in getModelAttachment:', error)
        return []
    }
}

const previewModelAttachment = async (params) => {
    try {
        const url = '/download-file'

        const { data } = await AxiosConfig.get(url, {
            params,
            responseType: 'blob'
        })

        const objectUrl = URL.createObjectURL(data)

        return objectUrl
    } catch (error) {
        console.error('Error in previewModelAttachment:', error)
        return []
    }
}

const deleteFileAttachment = async (id) => {
    try {
        const url = `file-attachment/${id}`

        const { data } = await AxiosConfig.delete(url)

        return data.data ? data.data : data
    } catch (error) {
        console.error('Error in deleteFileAttachment:', error)
        return []
    }
}

const deleteFileResource = async (id) => {
    try {
        const url = `documents/${id}`

        const { data } = await AxiosConfig.delete(url)

        return data.data ? data.data : data
    } catch (error) {
        console.error('Error in deleteFileResource:', error)
        return []
    }
}

const exportToPDF = async (
    modelName,
    params = {},
    method = 'get',
    filename = '',
    endpoint = 'generate_pdf'
) => {
    try {
        const url = `${modelName}/${endpoint}`

        const lowercaseMethod = method.toLowerCase()

        const blobHeader = {
            responseType: 'blob'
        }

        const { data } = await AxiosConfig[lowercaseMethod](
            url,
            lowercaseMethod === 'get'
                ? { params, ...blobHeader }
                : { ...params },
            blobHeader
        )

        const fileURL = window.URL.createObjectURL(new Blob([data]))
        const fileLink = document.createElement('a')

        fileLink.href = fileURL
        fileLink.setAttribute('download', `${filename}.pdf`)
        document.body.appendChild(fileLink)

        fileLink.click()

        if (!data) return []

        return data
    } catch (error) {
        console.error('Error in exportToPDF:', error)
        return []
    }
}

const deleteSignature = async (modelName, modelID) => {
    try {
        const url = `/${modelName}/${modelID}`

        const { data } = await AxiosConfig.delete(url)

        return data.data ? data.data : data
    } catch (error) {
        console.error('Error in deleteSignature:', error)
        return []
    }
}

const deleteMedia = async (modelName, questionId, mediaId) => {
    try {
        const url = `/${modelName}/${questionId}/delete_media/${mediaId}`

        const { data } = await AxiosConfig.post(url)

        return data.data ? data.data : data
    } catch (error) {
        console.error('Error in deleteMedia:', error)
        return []
    }
}

export default {
    createModel,
    deleteModel,
    getAll,
    getModelByID,
    updateModel,
    getModelAttachment,
    previewModelAttachment,
    deleteFileAttachment,
    exportToPDF,
    deleteFileResource,
    deleteSignature,
    deleteMedia,
    restoreModel
}
