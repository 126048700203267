import AxiosConfig from '@/api/config'

const baseURL = '/client-signature'

const uploadSignature = async (payload) => {
    try {
        const url = `${baseURL}`

        const { data } = await AxiosConfig.post(url, payload)
        return data.data ? data.data : data
    } catch (error) {
        console.error('Error in uploadSignature:', error)
        return []
    }
}

const updateSignature = async (id, payload) => {
    try {
        const url = `${baseURL}/${id}`

        const { data } = await AxiosConfig.put(url, payload)
        return data.data ? data.data : data
    } catch (error) {
        console.error('Error in updateSignature:', error)
        return []
    }
}

const deleteSignature = async (id) => {
    try {
        const url = `${baseURL}/${id}`

        const { data } = await AxiosConfig.delete(url)
        return data.data ? data.data : data
    } catch (error) {
        console.error('Error in deleteSignature:', error)
        return []
    }
}

export default {
    uploadSignature,
    updateSignature,
    deleteSignature
}
