import API from '@/api'
import db from '@/offline/indexedDBHelper'

export default {
    namespaced: true,
    state: {
        user: {},
        authToken: '',
        timezone: 'UTC',
        loginStatus: false,
        tenantDate: ''
    },
    getters: {
        getUser: (state) => state.user,
        getUserId: (state) => state.user.id,
        getUserLanguage: (state) => state.user.lang,
        getTenantId: (state) => state.user.tenant?.id,
        getUserName: (state) => {
            return `${state.user.first_name} ${state.user.last_name}`
        },
        getAuthToken: (state) => state.authToken,
        getTimezone: (state) => state.timezone,
        isUserLoggedIn: (state) => Object.keys(state.user).length,
        getUserRole: (state) =>
            state.user && state.user.roles?.length ? state.user.roles[0].id : '',
        getLoginStatus: (state) => state.loginStatus,
        getTenantDate: (state) => state.tenantDate
    },
    mutations: {
        SET_USER (state, user) {
            state.user = user
        },
        SET_AUTH_TOKEN (state, token) {
            state.authToken = token
        },
        SET_USER_TIMEZONE (state, timezone) {
            state.timezone = timezone
        },
        SET_LOGIN_STATUS (state, status) {
            state.loginStatus = status
        },
        SET_TENANT_DATE (state, date) {
            state.tenantDate = date
        }
    },
    actions: {
        async loginUser ({ commit }, loginPayload) {
            const { token, user, timezone, date } = await API.Auth.loginUser(
                loginPayload
            )

            if (!user) return

            console.log('logged in user:', user)
            commit('SET_AUTH_TOKEN', token)
            commit('SET_USER', user)
            commit('SET_USER_TIMEZONE', timezone)
            commit('SET_LOGIN_STATUS', true)
            commit('SET_TENANT_DATE', date)
            commit('SET_GLOBAL_DATE', date, { root: true })

            return user
        },
        async fetchUser ({ commit }, id) {
            const { data: user = {} } = await API.Auth.fetchUser(id)

            const { timezone, date } = user

            commit('SET_USER', user)
            commit('SET_USER_TIMEZONE', timezone)
            commit('SET_LOGIN_STATUS', true)
            commit('SET_TENANT_DATE', date)
            commit('SET_GLOBAL_DATE', date, { root: true })

            return user
        },
        logoutUser ({ commit }) {
            commit('SET_AUTH_TOKEN', '')
            commit('SET_USER', {})
            commit('SET_USER_TIMEZONE')
            commit('SET_LOGIN_STATUS', false)

            commit('DashboardModule/SET_NOTIFICATIONS', 0, { root: true })

            db.delete().then(() => {
                console.log('Database successfully deleted')
                db.open().then(() => {
                    console.log('db opened')
                }).catch((err) => {
                    console.error('Could not open database', err)
                })
            }).catch((err) => {
                console.error('Could not delete database', err)
            })
        }
    }
}
