import AxiosConfig from '@/api/config'
import db from '@/offline/indexedDBHelper'
import { fetchAndStoreData } from '.'

const baseURL = '/jobs'

const getStatuses = async () => {
    const url = `${baseURL}/status-types`
    return await fetchAndStoreData(url, db.job_status_types)
}

const getByDateRange = async (startDate, endDate) => {
    try {
        const url = `${baseURL}/bydate`

        const params = {
            start_date: startDate,
            end_date: endDate
        }

        const { data } = await AxiosConfig.get(url, { params })

        if (!data) return []

        return data
    } catch (error) {
        console.error('Error in getByDateRange:', error)
        return []
    }
}

const addForm = async (id, payload) => {
    try {
        const url = `${baseURL}/${id}/add-form`

        const { data } = await AxiosConfig.post(url, payload)

        if (!data) return []

        return data
    } catch (error) {
        console.error('Error in addForm:', error)
        return []
    }
}

const removeForm = async (id, payload) => {
    try {
        const url = `${baseURL}/${id}/remove-form`

        const { data } = await AxiosConfig.post(url, payload)

        if (!data) return []

        return data
    } catch (error) {
        console.error('Error in removeForm:', error)
        return []
    }
}

export default {
    getByDateRange,
    getStatuses,
    addForm,
    removeForm
}
