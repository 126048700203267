import AxiosConfig from '@/api/config'

const baseURL = '/form-questions-answers'

const getAnswerById = async (id) => {
    try {
        const { data } = await AxiosConfig.get(`${baseURL}/${id}`)

        return data
    } catch (error) {
        console.error('Error in getAnswerById:', error)
        return {}
    }
}

const createAnswer = async (params) => {
    try {
        const { data } = await AxiosConfig.post(baseURL, params)

        return data
    } catch (error) {
        console.error('Error in createAnswer:', error)
        return {}
    }
}

const updateMultipleAnswers = async (payload) => {
    try {
        const url = `${baseURL}/update-multiple`

        const { data } = await AxiosConfig.post(url, payload)

        return data
    } catch (error) {
        console.error('Error in updateMultipleAnswers:', error)
        return {}
    }
}

const deleteFormQuestion = async (answerId, mediaId) => {
    try {
        const url = `${baseURL}/${answerId}/delete_media/${mediaId}`

        const { data } = await AxiosConfig.post(url)

        return data
    } catch (error) {
        console.error('Error in deleteFormQuestion:', error)
        return {}
    }
}

const getMedia = async (mediaId, mediaName = 'download') => {
    try {
        const url = `/download-media/${mediaId}`

        const { data } = await AxiosConfig.get(url, {
            responseType: 'blob'
        })

        const fileURL = window.URL.createObjectURL(new Blob([data]))
        const fileLink = document.createElement('a')

        fileLink.href = fileURL
        fileLink.setAttribute('download', mediaName)
        document.body.appendChild(fileLink)

        fileLink.click()

        if (!data) return []

        return data
    } catch (error) {
        console.error('Error in getMedia:', error)
        return []
    }
}

export default {
    updateMultipleAnswers,
    getAnswerById,
    createAnswer,
    deleteFormQuestion,
    getMedia
}
