import AxiosConfig from '@/api/config'

const baseURL = '/reports'

const getWorkTimeSheetsReport = async (payload) => {
    try {
        const url = `${baseURL}/rope_hour_reports`

        const { data } = await AxiosConfig.get(url, payload)

        return data || []
    } catch (error) {
        console.error('Error in getWorkTimeSheetsReport:', error)
        return []
    }
}

const getJobNotesReport = async (payload) => {
    try {
        const url = `${baseURL}/notes_per_job`

        const { data } = await AxiosConfig.get(url, payload)

        return data || []
    } catch (error) {
        console.error('Error in getJobNotesReport:', error)
        return []
    }
}

const getWorkAndSafetyReport = async (payload) => {
    try {
        const url = `${baseURL}/rope_hours_summary`

        const { data } = await AxiosConfig.get(url, payload)

        return data || []
    } catch (error) {
        console.error('Error in getWorkAndSafetyReport:', error)
        return []
    }
}

const getAttendanceSheetReport = async (payload) => {
    try {
        const url = `${baseURL}/rope_hours_per_job`

        const { data } = await AxiosConfig.get(url, payload)

        return data || []
    } catch (error) {
        console.error('Error in getAttendanceSheetReport:', error)
        return []
    }
}

const getFormsLibraryReport = async (payload) => {
    try {
        const url = `${baseURL}/get_forms_data_for_job`

        const { data } = await AxiosConfig.get(url, payload)

        return data || []
    } catch (error) {
        console.error('Error in getFormsLibraryReport:', error)
        return []
    }
}

export default {
    getWorkTimeSheetsReport,
    getJobNotesReport,
    getWorkAndSafetyReport,
    getAttendanceSheetReport,
    getFormsLibraryReport
}
