import AxiosConfig from '@/api/config'
import db from '@/offline/indexedDBHelper'
import { fetchAndStoreData } from '.'

const baseURL = '/form-questions'

const getAllQuestions = async (params) => {
    try {
        const { data } = await AxiosConfig.get(baseURL, { params })

        if (!data) return {}

        return data
    } catch (error) {
        console.error('Error in getAllQuestions:', error)
        return {}
    }
}

const updateMultipleQuestions = async (payload) => {
    try {
        const url = `${baseURL}/update-multiple`

        const { data } = await AxiosConfig.post(url, payload)

        if (!data) return {}

        return data
    } catch (error) {
        console.error('Error in updateMultipleQuestions:', error)
        return {}
    }
}

const createQuestion = async (payload) => {
    try {
        const { data } = await AxiosConfig.post(baseURL, payload)

        if (!data) return {}

        return data
    } catch (error) {
        console.error('Error in createQuestion:', error)
        return {}
    }
}

const updateQuestion = async (formID, payload) => {
    try {
        const url = `${baseURL}/${formID}`

        const { data } = await AxiosConfig.patch(url, payload)

        if (!data) return {}

        return data
    } catch (error) {
        console.error('Error in updateQuestion:', error)
        return {}
    }
}

const deleteQuestion = async (formID) => {
    try {
        const url = `${baseURL}/${formID}`

        const { data } = await AxiosConfig.delete(url)

        if (!data) return {}

        return data.data ? data.data : data
    } catch (error) {
        console.error('Error in deleteQuestion:', error)
        return {}
    }
}

const getInputTypes = async () => {
    try {
        const url = `${baseURL}/input-types`
        return await fetchAndStoreData(url, db.form_questions_input_types)
    } catch (error) {
        console.error('Error in getInputTypes:', error)
        return []
    }
}

export default {
    getAllQuestions,
    createQuestion,
    updateQuestion,
    deleteQuestion,
    getInputTypes,
    updateMultipleQuestions
}
