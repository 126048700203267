import AxiosConfig from '@/api/config'

const baseURL = '/dashboard'

const getStats = async () => {
    const url = `${baseURL}/stats`

    const { data } = await AxiosConfig.get(url)

    return data.data ? data.data : data
}

const getNotifications = async ({ type = 'all', page = 1 }) => {
    try {
        const url = `${baseURL}/notifications?type=${type}&page=${page}`

        const { data } = await AxiosConfig.get(url)

        if (!data) return []

        return data
    } catch (error) {
        console.error('Error in getNotifications:', error)
        return []
    }
}

const clearNotifications = async () => {
    try {
        const url = `${baseURL}/notifications/markread`

        const { data } = await AxiosConfig.get(url)

        if (!data) return []

        return data
    } catch (error) {
        console.error('Error in clearNotifications:', error)
        return []
    }
}

const clearNotification = async (modelID) => {
    try {
        const url = `${baseURL}/notifications/${modelID}/markread`

        const { data } = await AxiosConfig.post(url)

        return data.data ? data.data : data
    } catch (error) {
        console.error('Error in clearNotification:', error)
        return []
    }
}

export default {
    clearNotifications,
    getNotifications,
    getStats,
    clearNotification
}
