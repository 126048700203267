import AxiosConfig from '@/api/config'
import db from '@/offline/indexedDBHelper'
import { fetchAndStoreData } from '.'

const baseURL = '/expenses'

const getExpensesCategories = async () => {
    const apiUrl = `${baseURL}/all-categories`
    return await fetchAndStoreData(apiUrl, db.expenses_categories)
}

const getCurrencyTypes = async () => {
    const apiUrl = `${baseURL}/currencies-types`
    return await fetchAndStoreData(apiUrl, db.currencies)
}

const getAllExpenses = async (params) => {
    try {
        const { data } = await AxiosConfig.get(baseURL, { params })
        return data
    } catch (error) {
        console.error('Error in getAllExpenses:', error)
        return []
    }
}

const getExpenseByID = async (expenseID) => {
    try {
        const url = `${baseURL}/${expenseID}`

        const { data } = await AxiosConfig.get(url)

        return data.data ? data.data : data
    } catch (error) {
        console.error('Error in getExpenseByID:', error)
        return {}
    }
}

const createExpense = async (payload) => {
    try {
        const { data } = await AxiosConfig.post(baseURL, payload)

        return data.data ? data.data : data
    } catch (error) {
        console.error('Error in createExpense:', error)
        return {}
    }
}

const updateExpense = async (expenseID, payload) => {
    try {
        const url = `${baseURL}/${expenseID}`

        const { data } = await AxiosConfig.path(url, payload)

        return data.data ? data.data : data
    } catch (error) {
        console.error('Error in updateExpense:', error)
        return {}
    }
}

const deleteExpense = async (expenseID, payload) => {
    try {
        const url = `${baseURL}/${expenseID}`

        const { data } = await AxiosConfig.delete(url, payload)

        return data.data ? data.data : data
    } catch (error) {
        console.error('Error in deleteExpense:', error)
        return {}
    }
}

const getExpenseAttachments = async (expenseID) => {
    try {
        const url = `${baseURL}/${expenseID}/attachments`

        const { data } = await AxiosConfig.get(url)

        if (!data) return []

        return data
    } catch (error) {
        console.error('Error in getExpenseAttachments:', error)
        return []
    }
}

const uploadExpenseAttachment = async (expenseID, payload) => {
    try {
        const url = `${baseURL}/${expenseID}/attachfile`

        const { data } = await AxiosConfig.post(url, payload)

        if (!data) return []

        return data
    } catch (error) {
        console.error('Error in uploadExpenseAttachment:', error)
        return []
    }
}

export default {
    getExpensesCategories,
    getAllExpenses,
    getExpenseByID,
    createExpense,
    updateExpense,
    deleteExpense,
    getExpenseAttachments,
    uploadExpenseAttachment,
    getCurrencyTypes
}
