import AxiosConfig from '@/api/config'
import db from '@/offline/indexedDBHelper'
import { fetchAndStoreData } from '.'
import store from '@/store'

const baseURL = '/tags'

const getTags = async (params) => {
    try {
        const url = `${baseURL}/`

        const userId = store.getters['AuthModule/getUserId']

        if (!window.indexedDB) {
            const { data } = await AxiosConfig.get(url, {
                params: {
                    ...params,
                    dropdown: 1,
                    sort: 'name',
                    direction: 'asc'
                }
            })

            return data
        }

        const offlineData = await db.tags
            .where({ type: params.type, user_id: userId })
            .toArray()

        if (offlineData.length > 0) {
            return { data: offlineData }
        }

        const { data } = await AxiosConfig.get(url, {
            params: { ...params, dropdown: 1, sort: 'name', direction: 'asc' }
        })

        if (!data) return []

        // Save to IndexedDB
        const offlineDataToSave = data?.data?.map((item) => ({
            id: item.id,
            type: params.type,
            user_id: userId,
            ...item
        }))

        await db.tags.bulkPut(offlineDataToSave)

        return data
    } catch (error) {
        console.error('Error in getTags:', error)
        return []
    }
}

const getTagTypes = async () => {
    try {
        const url = `${baseURL}/types`
        const data = await fetchAndStoreData(url, db.tags_types)

        const hasItemWithValueProperty = data.some((item) => item.value)

        if (hasItemWithValueProperty) return data.map((item) => item.value)
        return data
    } catch (error) {
        console.error('Error in getTagTypes:', error)
        return []
    }
}

const addTag = async (payload) => {
    try {
        const url = `${baseURL}/`

        const { data } = await AxiosConfig.post(url, payload)

        if (!data) return []

        return data
    } catch (error) {
        console.error('Error in addTag:', error)
        return []
    }
}

const editTag = async (id, payload) => {
    try {
        const url = `${baseURL}/${id}`

        const { data } = await AxiosConfig.put(url, payload)

        if (!data) return []

        return data
    } catch (error) {
        console.error('Error in editTag:', error)
        return []
    }
}

const deleteTag = async (id) => {
    try {
        const url = `${baseURL}/${id}`

        const { data } = await AxiosConfig.delete(url)

        if (!data) return []

        return data
    } catch (error) {
        console.error('Error in deleteTag:', error)
        return []
    }
}

export default {
    getTags,
    getTagTypes,
    addTag,
    editTag,
    deleteTag
}
