import AxiosConfig from '@/api/config'
import db from '@/offline/indexedDBHelper'
import { fetchAndStoreData } from '.'

const baseURL = '/workschedules'

const getStatuses = async () => {
    const url = `${baseURL}/status-types`
    return await fetchAndStoreData(url, db.workschedule_status_types)
}

const addTeamLead = async (payload) => {
    try {
        const url = `${baseURL}/addteamlead`

        const { data } = await AxiosConfig.post(url, payload)

        if (!data) return []

        return data
    } catch (error) {
        console.error('Error in addTeamLead:', error)
        return []
    }
}

const addOperative = async (payload) => {
    try {
        const url = `${baseURL}/addoperative`

        const { data } = await AxiosConfig.post(url, payload)

        if (!data) return []

        return data
    } catch (error) {
        console.error('Error in addOperative:', error)
        return []
    }
}

const addPartimeOperative = async (payload) => {
    try {
        const url = `${baseURL}/add-part-time-operative`

        const { data } = await AxiosConfig.post(url, payload)

        if (!data) return []

        return data
    } catch (error) {
        console.error('Error in addPartimeOperative:', error)
        return []
    }
}

const removeOperative = async (payload) => {
    try {
        const url = `${baseURL}/removeoperative`

        const { data } = await AxiosConfig.post(url, payload)

        if (!data) return []

        return data
    } catch (error) {
        console.error('Error in removeOperative:', error)
        return []
    }
}

const clone = async (payload) => {
    try {
        const url = `${baseURL}/clone`

        const { data } = await AxiosConfig.post(url, payload)

        if (!data) return []

        return data
    } catch (error) {
        console.error('Error in clone:', error)
        return []
    }
}

const sendSMS = async (payload) => {
    try {
        const url = `${baseURL}/send-notifications`

        const { data } = await AxiosConfig.post(url, payload)

        if (!data) return []

        return data
    } catch (error) {
        console.error('Error in sendSMS:', error)
        return []
    }
}

const switchTeamLead = async (payload) => {
    try {
        const url = `${baseURL}/switch-team-lead`

        const { data } = await AxiosConfig.post(url, payload)

        if (!data) return []

        return data
    } catch (error) {
        console.error('Error in switchTeamLead:', error)
        return []
    }
}

const getSignatures = async (id) => {
    try {
        const { data } = await AxiosConfig.get(`${baseURL}/${id}/signatures`)

        return data
    } catch (error) {
        console.error('Error in getSignatures:', error)
        return []
    }
}

const uploadSignature = async (id, payload) => {
    try {
        const { data } = await AxiosConfig.post(
            `${baseURL}/${id}/signature`,
            payload
        )

        return data
    } catch (error) {
        console.error('Error in uploadSignature:', error)
        return []
    }
}

export default {
    getStatuses,
    addOperative,
    addTeamLead,
    clone,
    sendSMS,
    addPartimeOperative,
    removeOperative,
    switchTeamLead,
    getSignatures,
    uploadSignature
}
